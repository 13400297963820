<template>
  <Row class="yl-detail">
    <Col style="width: 26%">
      <div class="left">
        <span class="title"><span class="title-text">家庭信息</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <div class="left1" style="height: 25%">
          <div style="height: 60%; padding: 1.5rem">
            <Row class="top-box" type="flex" justify="start" align="middle">
              <Col>
                <div style="height: 35px; color: #6eddf1; font-weight: bold; font-size: 18px">家庭名称</div>
                <div class="box">
                  <div class="label">有效期:</div>
                  <div style="padding-left: 10px">2024年10月01日 - 2025年10月01日</div>
                </div>
                <div class="box">
                  <div class="label">所属社区:</div>
                  <div style="padding-left: 10px">秦皇岛市海港区矿院里1栋1单元101室</div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div class="left1" style="height: 40%">
          <span class="title"><span class="title-text">老人信息</span></span>
          <Row type="flex" :gutter="10" justify="space-around" align="middle" style="padding: 1.5rem; height: 100%">
            <Col :span="10" style="text-align: center">
              <img src="../assets//images/oldman.jpg" style="width: 100%; height: 80%" />
              <div style="color: #6eddf1; font-weight: bold; font-size: 16px">姓名</div>
            </Col>
            <Col :span="14" class="top-box">
              <div class="box">
                <div class="label">性别:</div>
                <div class="content" style="padding-left: 10px">男</div>
              </div>
              <div class="box">
                <div class="label">年龄:</div>
                <div class="content" style="padding-left: 10px">68</div>
              </div>
              <div class="box">
                <div class="label">电话:</div>
                <div class="content" style="padding-left: 10px">13333333333</div>
              </div>
              <div class="box">
                <div class="label">状态:</div>
                <div class="content" style="padding-left: 10px">独居</div>
              </div>
              <div class="box">
                <div class="label">病史:</div>
                <div class="content" style="padding-left: 10px">无病史</div>
              </div>
              <div class="box">
                <div class="label">注意事项:</div>
                <div class="content-more" style="padding-left: 10px">老人曾经摔倒</div>
              </div>
            </Col>
          </Row>
        </div>
        <div style="height: 45%">
          <div class="left1" style="height: 80%">
            <span class="title"><span class="title-text">家属信息</span></span>
            <Row :gutter="10" type="flex" justify="space-around" align="middle" style="padding: 1.5rem; height: 100%">
              <Col :span="10" style="text-align: center">
                <img src="../assets//images/sun.jpg" style="width: 100%; height: 90%" />
                <div style="color: #6eddf1; font-weight: bold; font-size: 16px">姓名</div>
              </Col>
              <Col :span="14" class="top-box">
                <div class="box">
                  <div class="label">性别:</div>
                  <div class="content" style="padding-left: 10px">男</div>
                </div>
                <div class="box">
                  <div class="label">关系:</div>
                  <div class="content" style="padding-left: 10px">亲属</div>
                </div>

                <div class="box">
                  <div class="label">电话:</div>
                  <div class="content" style="padding-left: 10px">13333333333</div>
                </div>
                <div class="box">
                  <div class="label">家庭住址:</div>
                  <div class="content" style="padding-left: 10px">秦皇岛市海港区</div>
                </div>
                <div class="box">
                  <div class="label">单位名称:</div>
                  <div class="content-more" style="padding-left: 10px">秦皇岛市某某公司</div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Col>
    <Col style="width: 48%; padding: 0 1%">
      <div class="center-top">
        <Row class="header" type="flex" justify="center" align="middle">
          <Col :span="6"> 设备序列号 </Col>
          <Col :span="10">设备名称</Col>
          <Col :span="4">设备分类</Col>
          <Col :span="4">设备型号</Col>
        </Row>
        <div v-if="this.equipmenList.length > 0">
          <Row class="list-box" type="flex" justify="center" align="middle" v-for="(item, index) in equipmenList" :key="index">
            <Col :span="6" class="col-box">
              {{ item.equipmentId }}
            </Col>
            <Col :span="10" class="col-box">{{ item.equipmentName }}</Col>
            <Col :span="4" class="col-box">{{ item.equipmentCategory }}</Col>
            <Col :span="4" class="col-box">{{ item.equipmentModel }}</Col>
          </Row>
        </div>
        <div v-else>
          <Row type="flex" justify="center" align="middle">
            <Col :span="10" class="empty-box">
              <img src="../assets//images/emptydata.png" class="img" />
            </Col>
          </Row>
        </div>
      </div>
    </Col>
    <Col style="width: 26%">
      <div class="right-1">
        <div class="right1-1">
          <span class="title"><span class="title-text">设备告警消息</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="wraning-message-box" style="height: 100%">
            <Row type="flex" justify="space-between" align="middle">
              <Col :span="6">共{{ warningNumber }}条</Col>
              <Col :span="14" class="label-box">
                <div class="title-text">已处理</div>
                <div class="title-text">未处理</div>
                <div class="title-text">误报</div>
                <div class="title-text">已派单</div>
              </Col>
            </Row>
            <div class="center-top" style="margin-top: 10px">
              <Row class="header" type="flex" justify="center" align="middle">
                <Col :span="9">来自</Col>
                <Col :span="9">地址</Col>
                <Col :span="6">日期</Col>
              </Row>
              <div v-if="this.warningMessageList.length > 0">
                <Row :gutter="20" class="list-box" type="flex" justify="center" align="middle" v-for="(item, index) in warningMessageList" :key="index">
                  <Col :span="10" class="col-box">
                    <Tooltip max-width="200" :content="item.equipmentName" theme="light" :transfer="true">
                      <div class="item">{{ item.equipmentName }}</div>
                    </Tooltip>
                  </Col>

                  <Col :span="9" class="col-box">
                    <Tooltip max-width="200" :content="item.address" theme="light" :transfer="true">
                      <div class="item">{{ item.address }}</div>
                    </Tooltip>
                  </Col>
                  <Col :span="5" class="col-box">
                    <Tooltip max-width="200" :content="item.date" theme="light" :transfer="true">
                      <div class="item">
                        {{ item.date }}
                      </div>
                    </Tooltip>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="right-2">
        <div class="right1-1">
          <span class="title"><span class="title-text">工单详情</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <dv-scroll-board ref="scollBoard" :config="boardConfig2" style="width: 100%; height: 90%; padding: 1rem" />
        </div>
      </div>
    </Col>
  </Row>
</template>

<script>
export default {
  name: 'page3',

  data() {
    return {
      warningNumber: 10,
      warningMessageList: [
        {
          equipmentName: '江云 跌倒 雷达 MR(CSEPMR511:863B5BF68)',
          address: '秦皇岛海港区**小区',
          date: '2024-10-10'
        },
        {
          equipmentName: '江云 跌倒 雷达 MR(CSEPMR511:863B5BF68)',
          address: '秦皇岛海港区**小区',
          date: '2024-10-18'
        },
        {
          equipmentName: '江云 跌倒 雷达 MR(CSEPMR511:863B5BF68)',
          address: '秦皇岛海港区**小区',
          date: '2024-10-20'
        }
      ],
      equipmenList: [
        {
          equipmentId: 'CSEPMR511:D94E688A1',
          equipmentName: '高恩厚 跌倒 雷达 MR(CSEPMR511:D94E688A1)',
          equipmentCategory: '跌倒雷达',
          equipmentModel: 'CS-EP-MR511'
        },
        {
          equipmentId: 'CSEPMR511:863B5BF68',
          equipmentName: '于江云 跌倒 雷达 MR(CSEPMR511:863B5BF68)',
          equipmentCategory: '跌倒雷达',
          equipmentModel: 'CS-EP-MR511'
        },
        {
          equipmentId: 'CSEPMR511:863B5BF68',
          equipmentName: '江云 跌倒 雷达 MR(CSEPMR511:863B5BF68)',
          equipmentCategory: '跌倒雷达',
          equipmentModel: 'CS-EP-MR511'
        },
        {
          equipmentId: 'CSEPMR511:D94E688A1',
          equipmentName: '高恩厚 跌倒 雷达 MR(CSEPMR511:D94E688A1)',
          equipmentCategory: '跌倒雷达',
          equipmentModel: 'CS-EP-MR511'
        },
        {
          equipmentId: 'CSEPMR511:863B5BF68',
          equipmentName: '于江云 跌倒 雷达 MR(CSEPMR511:863B5BF68)',
          equipmentCategory: '跌倒雷达',
          equipmentModel: 'CS-EP-MR511'
        },
        {
          equipmentId: 'CSEPMR511:863B5BF68',
          equipmentName: '江云 跌倒 雷达 MR(CSEPMR511:863B5BF68)',
          equipmentCategory: '跌倒雷达',
          equipmentModel: 'CS-EP-MR511'
        }
      ],

      boardConfig2: {
        header: ['工单类型', '处理状态', '工单等级', '服务人员', '时间'],
        data: [
          ['配网', '未开始', '紧急', '小莉', '2024-10-10'],
          ['维修', '处理中', '高', '小莉', '2024-10-12'],
          ['服务', '已完成', '中', '小莉', '2024-10-11'],
          ['紧急救助', '异常工单', '低', '小莉', '2024-10-12'],
          ['紧急救助', '超时工单', '低', '小莉', '2024-10-12'],
          ['紧急救助', '已取消', '低', '小莉', '2024-10-12']
        ],
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        // indexHeader: '索引',
        columnWidth: [100, 100, 80, 100, 120],
        // index: true,
        align: ['center']
      }
    }
  },
  mounted() {
    this.getBoard()
  },
  methods: {
    getBoard() {
      const board = this.$refs.scollBoard
      console.log(board)
    }
  }
}
</script>

<style lang="less" scoped>
.yl-detail {
  height: 100%;
  width: 100%;
  padding: 14px 20px 20px;
  overflow: hidden;
  .ivu-col {
    height: 100%;
    float: left;
  }

  .left,
  .right1-1,
  .center-bottom {
    height: 100%;
    border: 1px solid #0d2451;
    position: relative;

    #left_5 {
      height: 100%;
      width: 45%;
      float: left;
    }

    #left_6 {
      height: 100%;
      width: 55%;
      float: left;
    }

    .circular {
      height: 100%;

      .canvas {
        height: 100%;
        width: 30%;
        float: left;

        .subtitle {
          font-size: 12px;
          font-weight: bold;
          color: #fff;
          height: 25px;
          padding: 10px 0;
          text-align: center;
        }

        .canvasList {
          height: calc(~'100% - 25px');
          text-align: center;
        }
      }
    }

    .left1 {
      border-bottom: 1px solid #0d2451;
      .top-box {
        .box {
          display: flex;

          height: 35px;
          color: #f5f5f5;
          // font-weight: bold;
          font-size: 14px;
          .label {
            width: 80px;
            text-align: right;
            white-space: nowrap;
          }
          .content {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .content-more {
            width: 100%;
            height: 40px;

            display: -webkit-box;
            -webkit-line-clamp: 2; /* 最大行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            // text-overflow: ellipsis;
          }
        }
      }
    }

    .title {
      position: absolute;
      display: inline-block;
      color: #6eddf1;
      border: 2px solid #6eddf1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102e;
        display: inline-block;
        padding: 0 4px;
        transform: translateY(-5px);
      }
    }
  }

  .center-top {
    height: 100%;
    .header {
      color: #6eddf1;
      font-size: 18px;
      font-weight: 700;
      height: 50px;
    }
    .list-box {
      color: #fff;
      font-size: 14px;
      .col-box {
        width: 100%;
        height: 50px;

        ::v-deep .ivu-tooltip {
          width: inherit;
        }
        .item {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .empty-box {
      // position: relative;

      text-align: center;
      color: #4f4c4c;
      margin-top: 80px;
      font-size: 20px;
      letter-spacing: 1px;
      .img {
        position: relative;

        width: 100%;
        height: 100%;
      }
    }
  }

  .center-bottom {
    height: 40%;

    .bottom-radars {
      height: 55%;
    }

    .bottom-bars {
      height: 45%;
    }
  }

  .right-1 {
    height: 60%;

    .right1-1 {
      height: 96%;
      .wraning-message-box {
        // display: flex;
        // justify-content: space-between;
        color: #fff;
        width: 100%;
        padding: 1.5rem;
        .label-box {
          display: flex;
          justify-content: space-around;

          .title-text {
            width: 24%;
            text-align: center;
            height: 30px;
            line-height: 30px;
            border: 1px solid #0d2451;
            cursor: pointer;
          }
        }
      }
    }
  }

  .right-2 {
    height: 40%;
  }
}
</style>
